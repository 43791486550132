<template>
  <div class="app_con">
    <div class="p-20">
      <div class="m-b-20">
        <el-row>
          <el-col :span="3">
            <el-button type="success" @click="onCardAuth">授权</el-button>
          </el-col>
          <el-col :span="21">
            <div class="search">
              <el-input
                placeholder="根据姓名查询"
                v-model="filtterData.personName"
                class="searchInput"
              >
                <template slot="prepend">姓名</template>
              </el-input>
              <el-input
                placeholder="根据人员编号查询"
                v-model="filtterData.personCode"
                class="searchInput"
              >
                <template slot="prepend">人员编号</template>
              </el-input>
              <el-input
                placeholder="根据卡号查询"
                v-model="filtterData.cardNumber"
                class="searchInput"
              >
                <template slot="prepend">卡号</template>
              </el-input>

              <div class="searchInput el-input-group">
                <div class="el-input-group__prepend">授权状态</div>
                <el-select
                  filterable
                  v-model="filtterData.authorizeStatus"
                  clearable
                  placeholder="根据授权状态筛选"
                >
                  <el-option
                    v-for="item in authorizeStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="searchInput el-input-group">
                <div class="el-input-group__prepend">任务状态</div>
                <el-select
                  filterable
                  v-model="filtterData.taskStatus"
                  clearable
                  placeholder="根据任务状态筛选"
                >
                  <el-option
                    v-for="item in taskStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-table
        :data="cardList"
        border
        style="width: 100%"
        v-loading="loading"
        row-key="id"
        @row-dblclick="rowDblClick"
        @selection-change="getSelection($event)"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <template v-for="v in props">
          <el-table-column
            :key="v.id"
            :prop="v.prop"
            :label="v.label"
            :width="v.width ? v.width : ''"
          >
            <template slot-scope="scope">
              <span
                v-if="v.type && v.type == 'cardStatus'"
                :class="statusManage.cardAuthStatus(scope.row.cardStatus).color"
              >
                {{ statusManage.cardAuthStatus(scope.row.cardStatus).name }}
              </span>
              <span
                v-if="v.type && v.type == 'authorizeStatus'"
                :class="
                  statusManage.authorizeStatus(scope.row.authorizeStatus).color
                "
              >
                <i
                  :class="
                    statusManage.authorizeStatus(scope.row.authorizeStatus).icon
                  "
                ></i>
                {{
                  statusManage.authorizeStatus(scope.row.authorizeStatus).name
                }}
              </span>
              <!-- taskStatus  :class="statusManage.taskStatus(scope.row.taskStatus).color"-->
              <span
                v-if="v.type && v.type == 'taskStatus'"
                :class="statusManage.taskStatus(scope.row.taskStatus).color"
              >
                {{
                  statusManage.taskStatus(scope.row.taskStatus).name
                }}双击查看详情
              </span>
              <span v-else>{{ scope.row[v.prop] }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <el-pagination
        background
        :current-page.sync="searchQuery.pageNum"
        :page-size="searchQuery.pageSize"
        :total="searchQuery.total"
        :layout="'sizes, prev, pager, next, jumper'"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>
    <el-dialog
      title="卡片授权"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      @close="dialogVisible = false"
    >
      <div class="p-l-r-30">
        <el-form
          :rules="rules"
          :model="faceForm"
          ref="faceForm"
          inline
          label-width="120px"
          class="inlineForm"
        >
          <el-form-item label="姓名" prop="cardNumbers">
            <el-select
              class="inlineFormInput"
              v-model="faceForm.cardNumbers"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in selectCardList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开门计划" prop="timeQuantumId">
            <el-select
              class="inlineFormInput"
              filterable
              v-model="faceForm.timeQuantumId"
              clearable
              placeholder="选择开门计划"
            >
              <el-option
                v-for="item in openPlanList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="门通道" prop="resouceCode">
            <el-select
              filterable
              multiple
              style="width: 200px"
              v-model="faceForm.resouceCode"
              clearable
              placeholder="选择门通道"
            >
              <el-option
                v-for="item in deviceList"
                :key="item.channelCode"
                :label="item.channelName"
                :value="item.channelCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button @click="save" type="primary">保存</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="卡详情"
      :visible.sync="dialogDetailVisible"
      width="50%"
      @close="dialogDetailVisible = false"
    >
      <div>
        <el-table :data="cardBydetail.cardPrivilegeDetails || []" border stripe>
          <el-table-column
            v-for="col in detailsColumns"
            :prop="col.prop"
            :key="col.id"
            :label="col.label"
            :width="col.width"
          >
            <!-- {{scope.$index + 1}} -->
            <template slot-scope="scope">
              <span v-if="col.type && col.type == 'sort'">
                {{ scope.$index + 1 }}
              </span>
              <span
                v-if="col.type && col.type == 'status'"
                :class="
                  statusManage.detailTaskStatus(scope.row.authorizeStatus).color
                "
              >
                {{
                  statusManage.detailTaskStatus(scope.row.authorizeStatus).name
                }}
              </span>
              <span v-else>{{ scope.row[col.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="dialogDetailVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";
import statusManage from "./utils/status";
export default {
  data() {
    return {
      rules: {
        cardNumbers: [
          { required: true, message: "请选择所授权的人员", trigger: "change" },
        ],
        timeQuantumId: [
          { required: true, message: "请选择开门计划", trigger: "change" },
        ],
        resouceCode: [
          {
            required: true,
            message: "请选择需要授权的门通道",
            trigger: "change",
          },
        ],
      },
      statusManage,
      loading: false,
      dialogVisible: false,
      dialogDetailVisible: false,
      authorizeStatusList: [
        {
          value: "1",
          label: "未授权",
        },
        {
          value: "2",
          label: "已授权",
        },
      ],
      taskStatusList: [
        {
          value: "1",
          label: "下发中",
        },
        {
          value: "0",
          label: "已下发",
        },
      ],

      faceForm: {
        cardNumbers: [],
        timeQuantumId: "",
        resouceCode: [],
        cardPrivilegeDetails: [
          {
            privilegeType: "1",
            resouceCode: "",
          },
        ],
      },
      cardList: [],
      selectCardList: [],
      searchQuery: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      filtterData: {
        authorizeStatus: "",
        cardNumber: "",
        personCode: "",
        personName: "",
        taskStatus: "",
      },
      props: [
        {
          label: "姓名",
          prop: "personName",
          align: "center",
        },
        {
          label: "部门",
          prop: "deptName",
          align: "center",
        },
        {
          label: "卡号",
          prop: "cardNumber",
          align: "center",
        },

        {
          label: "卡类型",
          prop: "cardType",
          align: "center",
        },
        {
          label: "卡片状态",
          type: "cardStatus",
          align: "center",
        },
        {
          label: "授权状态",
          type: "authorizeStatus",
          align: "center",
        },
        {
          label: "任务状态",
          type: "taskStatus",
          align: "center",
        },
      ],
      selecTionArr: [],
      deviceList: [],
      openPlanList: [],
      cardBydetail: {},
      detailsColumns: [
        {
          label: "序号",
          type: "sort",
          align: "center",
        },
        {
          label: "门禁点名称",
          prop: "resourceName",
          align: "center",
        },
        {
          label: "任务状态",
          type: "status",
          align: "center",
        },
      ],
    };
  },
  created() {
    this.getList();
    this.getOpenPlanList();
    this.getDeviceList();
  },
  watch: {
    filtterData: {
      handler() {
        this.getList();
      },
      // immediate: true,
      deep: true, //开启深度监听
    },
  },
  mounted() {},

  methods: {
    test(e) {
      console.log("qweqweqqwe", e);
    },
    //   添加卡权限细节
    addCardPrivilegeDetails() {
      this.faceForm.cardPrivilegeDetails.push({
        privilegeType: 1,
        resouceCode: "",
      });
    },
    // 删除卡权限细节
    deleteCardPrivilegeDetails(index) {
      this.faceForm.cardPrivilegeDetails.splice(index, 1);
    },
    // 卡片授权
    onCardAuth() {
      this.dialogVisible = true;
      this.faceForm.cardNumbers = this.selecTionArr.map((x) => {
        return x.cardNumber;
      });
      this.selectCardList = this.cardList.map((x) => {
        return {
          name: x.personName,
          id: x.cardNumber,
        };
      });
    },
    selectMultiple(e) {
      console.log("e", e);
    },

    getSelection(e) {
      console.log(e);
      this.selecTionArr = e;
    },
    onSizeChange(e) {
      this.searchQuery.pageNum = e;
      this.getList();
    },
    onPageChange(e) {
      this.searchQuery.pageNum = e;
      this.getList();
    },

    async getDeviceList() {
      try {
        let data = {
          pageNum: 1,
          pageSize: 30,
        };
        let result = await api.concierge.getList(data);

        if (result.code == 200) {
          // 判断code是否等于200
          if (result.data.code == 403) {
            // 如果data.coded 等于403 说明大华平台登录过期需要重新登录
            let dhLogin = await api.common.dhLogin();

            if (dhLogin.code == 200) {
              // 大华平台登录成功 后重新获取设备列表
              this.getDeviceList();
            }
          } else {
            // 过滤第一条数据不要
            this.deviceList = result.data.data.pageData
              .filter((x) => {
                return x.deviceCode != 1000023;
              })
              .map((x) => {
                return x.accessControlChannelList[0];
              });

            console.log("deviceList", this.deviceList);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async save() {
      // 表单校验
      this.$refs.faceForm.validate(async (valid) => {
        if (valid) {
          let { faceForm } = this;
          console.log("faceForm", faceForm);
          let data = {
            cardNumbers: faceForm.cardNumbers,
            timeQuantumId: faceForm.timeQuantumId,
            cardPrivilegeDetails: faceForm.resouceCode.map((x) => {
              return {
                privilegeType: "1",
                resouceCode: x,
              };
            }),
          };
          console.log("data", data);
          let result = await api.common.commonDhPost({
            apiName: "/dh/cardAuth/create",
            data,
          });

          if ((result.code == 200) & result.data.success) {
            this.dialogVisible = false;
            this.$message({
              type: "success",
              message: "授权成功",
            });
          }
          console.log("result", result);
        } else {
          return false;
        }
      });
    },

    // 获取卡片列表i
    async getList() {
      this.loading = true;
      let { $test, filtterData, searchQuery } = this;
      let data = {
        pageNum: searchQuery.pageNum,
        pageSize: searchQuery.pageSize,
      };

      // 获取列表时为保证传多余的参数 需要判断值是否为空  空值不传字段，非空再传
      for (const key in filtterData) {
        if (!$test.isEmpty(filtterData[key])) {
          data[key] = filtterData[key];
        }
      }
      //
      let res = await api.common.commonDhGet({
        apiName: "/dh/cardAuth",
        data,
      });
      this.loading = false;
      this.cardList = res.data.data.pageData;
      this.searchQuery.total = res.data.data.totalRows;
    },
    // 获取开门计划列表i
    async getOpenPlanList() {
      let data = {
        pageNum: 1,
        pageSize: 22,
      };

      //
      let res = await api.common.daList({
        apiName: "/dh/openPlan",
        data: data,
      });
      this.openPlanList = res.data.data.pageData;
    },
    //  每行双击
    async rowDblClick(e) {
      console.log("e", e);
      let data = { cardNumber: e.cardNumber };
      let res = await api.common.commonDhGet({
        apiName: "/dh/cardAuth/cardBydetail",
        data,
      });
      console.log("res", res);
      if (res.code == 200 && res.data.success) {
        this.cardBydetail = res.data.data;
        this.dialogDetailVisible = true;
      } else {
        // 获取卡详情失败
        console.log("error", res);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inlineForm {
  /deep/ .el-form-item:not(odd) {
    margin-right: 65px;
  }
}
.inlineFormInput {
  width: 300px;
}
.fullWidth {
  width: 100% !important;
  overflow: hidden;
}
.avatar-uploader {
  // border: 1px dashed #d9d9d9;
  /deep/ .el-upload--picture-card {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 80px;
    height: 80px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
</style>
